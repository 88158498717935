import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

console.log({ BASE_API_URL });

export const client = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-type": "multipart/form-data",
  },
});
