import { client } from "./client";

const INVOICE_API_ROUTE = process.env.REACT_APP_INVOICE_API_ROUTE;

console.log({ INVOICE_API_ROUTE });

export const sendInvoice = async (input) => {
  try {
    console.log({ client });
    const { data } = await client.post(INVOICE_API_ROUTE, {
      ...input,
    });
    return { success: true, data };
  } catch (error) {
    console.error(error);
    return { success: false, error };
  }
};
