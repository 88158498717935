/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import UploadIcon from "assets/icons/UploadIcon/index";
import CloseIcon from "assets/icons/CloseIcon/index";

export default function Form(props) {
  const { name, value, type, placeholder, className, errorResponse, required } =
    props;

  const [hasError, setHasError] = useState(null);

  let pattern = "";
  if (type === "email") pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (type === "tel") pattern = "[0-9]*";

  const onChange = (event) => {
    const target = {
      target: {
        // eslint-disable-next-line object-shorthand
        name,
        value: type === "file" ? event.target.files?.[0] : event.target.value,
      },
    };

    if (type === "email") {
      if (!pattern.test(event.target.value)) setHasError(errorResponse);
      else setHasError(null);
    }

    if (type === "tel") {
      if (event.target.validity.valid) props.onChange(target);
    } else {
      props.onChange(target);
    }

    if (required && type !== "email") {
      if (!event.target.value.length) {
        setHasError("This field is required");
      } else {
        setHasError(null);
      }
    }
  };

  if (type === "textarea") {
    return (
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        className={[
          "w-95 sm:w-192 lg:w-192.5 xl:w-192.5 p-4 mb-0 font-light text-lg text-theme-blue rounded border border-blue-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-400",
          hasError
            ? "border-red-500 focus:border-red-500 focus:ring-red-500"
            : "",
          className,
        ].join(" ")}
        onChange={onChange}
        rows="9"
        required
      />
    );
  }

  if (type === "tel") {
    return (
      <div className="relative">
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          pattern={pattern}
          value={value}
          className={[
            // "p-4 font-light text-lg text-theme-blue rounded border border-gray-400 focus:outline-none focus:ring-1 focus:ring-theme-purple",
            `p-4 font-light text-lg min-h-16 text-theme-blue border rounded transition  focus:outline-none focus:ring-1 ${
              hasError
                ? "border-red-500 focus:border-red-500 focus:ring-red-500"
                : "border-blue-400 focus:border-blue-500 focus:ring-blue-400"
            }`,
            className,
          ].join(" ")}
          onChange={onChange}
          required
        />
        {hasError && (
          <span className="absolute left-0 top-full text-xs text-red-500 p-0 rounded">
            {hasError}
          </span>
        )}
      </div>
    );
  }

  if (type === "file") {
    const [filename, setFilename] = useState(null);
    const [fileKey, setFileKey] = useState(Math.random().toString(36));
    const fileRef = useRef(null);

    const handleFileChange = (event) => {
      const file = event.target.files[0];

      if (file && file.type !== "application/pdf") {
        setHasError("Please upload a PDF file");
        return;
      }
      setFilename(file?.name);

      onChange(event);
    };

    const handleFileRemove = (event) => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      event.preventDefault();
      setFilename(null);

      setFileKey(Math.random().toString(36));
    };

    return (
      <label className="cursor-pointer" for={name}>
        <div className="width-fit my-2 pt-2 flex justify-center items-center">
          <UploadIcon className="h-10 w-10" stroke="#60a5fa" />
          <p className={`text-blue-400 ${!filename ? "font-light" : ""}`}>
            {filename || placeholder}{" "}
            {filename && (
              <CloseIcon
                className="h-5 w-5 inline-block cursor-pointer z-10"
                stroke="#60a5fa"
                onClick={handleFileRemove}
              />
            )}
          </p>
          <input
            className="appearance-none hidden"
            key={fileKey}
            ref={fileRef}
            type="file"
            id={name}
            name={name}
            value={value}
            onChange={handleFileChange}
          />
        </div>
        {hasError && (
          <span className=" text-xs text-red-500  p-0 rounded">{hasError}</span>
        )}
      </label>
    );
  }

  return (
    <div className="relative">
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        className={[
          `p-4 font-light text-lg min-h-16 text-theme-blue border rounded transition  focus:outline-none focus:ring-1 ${
            hasError
              ? "border-red-500 focus:border-red-500 focus:ring-red-500"
              : "border-blue-400 focus:border-blue-500 focus:ring-blue-400"
          }`,
          className,
        ].join(" ")}
        onChange={onChange}
        required
      />
      {hasError && (
        <span className="absolute left-0 top-full text-xs text-red-500  p-0 rounded">
          {hasError}
        </span>
      )}
    </div>
  );
}

Form.defaultProps = {
  errorResponse: "Please match the requested format.",
  type: "",
  placeholder: "",
  className: "",
};

Form.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  errorResponse: PropTypes.string,
};
