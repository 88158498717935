/* eslint-disable react/jsx-filename-extension */
import React from "react";

import Button from "elements/Button";

//Racx
//<span className="text-theme-purple">studio</span>

export default function BrandIcon() {
  return (
    <Button className="" type="link" href="/">
      <p className="text-4xl font-comfortoo text-transparent bg-clip-text bg-gradient-to-r to-indigo-900 from-blue-400">
        ByteTactix
      </p>
    </Button>
  );
}
