/* eslint-disable no-useless-escape */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { Component } from "react";

import Fade from "react-reveal/Fade";
import * as emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Form from "elements/Form";
import Button from "elements/Button";
import Dropdown from "elements/Dropdown/index";
import { sendInvoice } from "../api/sendInvoice";

const PROJECT_TYPE_OPTIONS = [
  { value: "web_app", label: "Web App" },
  { value: "mobile_app", label: "Mobile App" },
  { value: "scraper", label: "Scraper" },
  { value: "cloud_service", label: "Cloud Service" },
  { value: "other", label: "Other" },
];

const PROJECT_BUDGET_OPTIONS = [
  { value: 0, label: "< $500" },
  { value: 500, label: "$500 - $1,000" },
  { value: 1000, label: "$1,000 - $3,000" },
  { value: 3000, label: "$3,000 - $10,0000" },
  { value: 10000, label: "> $10,000" },
];

export default function DiscussForm(props) {
  const { data, resetForm } = props;

  const submitEmail = async () => {
    /*
    data: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        projectType: "",
        budget: "",
        projectName: "",
        company: "",
        description: "",
        attachment: null,
      },
      */
    const {
      firstName,
      lastName,
      email,
      projectType,
      projectName,
      phoneNumber,
      budget,
      description,
      attachment,
    } = data;

    // const templateParams = {
    //   from_name: `${name} - ${company} ( ${phone} - ${email} )`,
    //   to_name: "Racxstudio",
    //   message: projectIdea,
    // };

    console.log({ data });

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      projectType !== "" &&
      projectName !== "" &&
      budget !== "" &&
      (!!description || !!attachment)
    ) {
      // emailjs
      //   .send(
      //     "service_h4gtndg",
      //     "template_a9tvs7a",
      //     templateParams,
      //     "user_csqIxzN5mKsl1yw4ffJzV"
      //   )
      //   .then(
      //     () => {
      //       toast.success("Success! we'll get back to you soon. Thank you!");
      //       resetForm();
      //     },
      //     (error) => {
      //       toast.error(error);
      //     }
      //   );
      await sendInvoice({
        full_name: firstName + " " + lastName,
        email,
        project_type: projectType,
        project_name: projectName,
        phone_number: phoneNumber,
        budget,
        description,
        attachment,
      });
      toast.success("Success! we'll get back to you soon. Thank you!");
      // resetForm();
    } else {
      toast.error("Please fill out the blank form.");
    }
  };

  return (
    <section className="flex flex-col container mx-auto mt-10 justify-center">
      <Fade bottom>
        <h1 className="text-5xl text-theme-blue text-center font-bold">
          Lets Discuss
        </h1>

        <p className="font-light text-lg text-gray-400 text-center mb-12">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Please fill out the form below to discuss your project and we'll get
          back to you in less than 24 hours.
        </p>

        {/* <div className="relative flex flex-col z-10"> */}

        <div className="flex flex-col gap-3 justify-items-center sm:gap-4 md:gap-5">
          <div className="flex flex-col gap-2 mx-auto sm:gap-3 sm:flex-row sm:pl-3 md:gap-4 md:pl-4">
            <Form
              id="firstName"
              name="firstName"
              type="text"
              value={data.firstName}
              placeholder="First Name*"
              className=""
              onChange={props.onChange}
              required
            />
            <Form
              id="lastName"
              name="lastName"
              type="text"
              value={data.lastName}
              placeholder="Last Name*"
              className=""
              onChange={props.onChange}
              required
            />
          </div>

          <div className="flex flex-col gap-2 mx-auto sm:gap-3 sm:flex-row sm:pr-3 md:gap-4 md:pr-4">
            <Form
              id="email"
              name="email"
              type="email"
              value={data.email}
              placeholder="Email*"
              className=""
              onChange={props.onChange}
              required
            />
            <Form
              id="phone_number"
              name="phone_number"
              type="tel"
              value={data.phone}
              placeholder="Phone Number"
              className=""
              onChange={props.onChange}
            />
          </div>

          <div className="flex flex-col gap-2 mx-auto sm:gap-3 sm:flex-row sm:pl-3 md:gap-4 md:pl-4">
            <Dropdown
              name="projectType"
              options={PROJECT_TYPE_OPTIONS}
              placeholder="Project Type*"
              onChange={props.onChange}
            />
            <Dropdown
              name="budget"
              options={PROJECT_BUDGET_OPTIONS}
              placeholder="Project Budget*"
              onChange={props.onChange}
            />
          </div>

          <div className="flex flex-col gap-2 mx-auto sm:gap-3 sm:flex-row sm:pr-3 md:gap-4 md:pr-4">
            <Form
              id="projectName"
              name="projectName"
              type="projectName"
              value={data.projectName}
              placeholder="Project Name*"
              className=""
              onChange={props.onChange}
              required
            />
            <Form
              id="company"
              name="company"
              type="text"
              value={data.company}
              placeholder="Company"
              className=""
              onChange={props.onChange}
            />
          </div>

          <div className="mx-auto flex flex-col gap-2">
            <div className="width-fit mx-auto">
              <Form
                id="attachment"
                name="attachment"
                type="file"
                // value={data.attachment}
                placeholder="Upload PDF"
                className=""
                onChange={props.onChange}
              />
            </div>
            <div className="flex justify-center mb-2">
              <p className="text-gray-400 font-light">- OR -</p>
            </div>
            <Form
              id="description"
              name="description"
              type="textarea"
              value={data.description}
              placeholder="Describe your Project"
              className=""
              onChange={props.onChange}
            />
          </div>

          <Button
            className="text-xl mx-auto px-12 py-3 mt-5 bg-blue-400 text-white rounded-full border-1 border-blue-400 hover:bg-blue-500 border-blue-500 transition duration-200 focus:outline-none"
            type="button"
            onClick={submitEmail}
          >
            Submit
          </Button>
        </div>
      </Fade>

      <ToastContainer />
    </section>
  );
}
