/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from "react";
import Web from "assets/images/Web.png";
import Mobile from "assets/images/Mobile.png";
import Fade from "react-reveal/Fade";
import Scraping from "assets/images/Scraping.png";
import Consultancy from "assets/images/Consultancy.png";

const imgs = [Web, Mobile, Scraping, Consultancy];

export default function Service({ data }) {
  return (
    <div className="bg-gray-50">
      <div className="container mx-auto pt-20 pb-28">
        <Fade bottom>
          <h1 className="text-5xl text-theme-blue text-center font-bold">
            Our Services
          </h1>

          <p className="font-light text-lg text-gray-400 text-center mb-12">
            We are ready to scale up your business with our great collection of
            services.
          </p>
        </Fade>

        <div className="grid grid-rows-3 px-10 gap-8 md:grid-cols-3 md:grid-rows-1 md:gap-6 xl:gap-16">
          {data.map((item, index) => {
            console.log(item);
            return (
              <Fade bottom delay={200 * index}>
                <div>
                  <div className="bg-white group rounded-2xl shadow-2xl border border-light-theme-purple transform transition duration-500 hover:scale-105">
                    <img
                      src={imgs[index]}
                      alt="Service"
                      className="w-full max-w-sm m-auto rounded-t-2xl"
                    />
                    <h2 className="text-theme-blue text-center text-xl py-7 rounded-b-2xl">
                      {item.title}
                    </h2>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </div>
    </div>
  );
}
