/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from "react";

import Fade from "react-reveal/Fade";

import Salhab from "assets/images/salhab.jpeg";
import Bob from "assets/images/bob.jpeg";

const imgs = [Salhab, Bob];

export default function AllTeam({ data }) {
  return (
    <section className="container mx-0 sm:mx-auto">
      <div className="flex flex-wrap gap-3 justify-around">
        {data.map((item, index) => (
          <Fade bottom delay={200 * index}>
            <div className="w-5/6 sm:w-auto">
              <div className="flex flex-row gap-3 h-36 sm:w-115 sm:h-44 md:130 px-4 rounded-xl shadow-xl border border-light-theme-purple transform transition duration-500 hover:scale-105">
                <div className="flex justify-center self-center w-24 h-24 sm:h-36 sm:w-36">
                  <img
                    src={imgs[index]}
                    alt="Team Member"
                    className="w-24 h-24 sm:w-36 sm:h-36 justify-center self-center object-cover"
                  />
                </div>
                <div className="flex flex-col justify-center gap-2 flex-grow ">
                  <h2 className="text-theme-blue text-center sm:text-left text-xl">
                    {item.name}
                  </h2>
                  <p className="font-light text-center sm:text-left text-gray-400 pl-2 mb-3">
                    {item.position}
                  </p>
                </div>
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </section>
  );
}
