import React, { useState, useEffect, useCallback } from "react";
import { RandomReveal } from "react-random-reveal";
import { TransitionGroup } from "react-transition-group";
import { TweenMax } from "gsap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import "./style.css";

const Grid = ({ prev, current, onChange }) => {
  const generateRandoms = useCallback(
    () =>
      Array.from({ length: 7 }, () =>
        Array.from({ length: 7 }, () => Math.floor(Math.random() * 2))
      ),
    []
  );

  const [randNums, setRandNums] = useState(generateRandoms());
  const [reverse, setReverse] = useState(false);
  const [logoAnimate, setLogoAnimate] = useState(false);

  // Code to generate and update random number here

  const transitionRandom = (e) => {
    TweenMax.set(e.target, { x: prev });
    TweenMax.to(e.target, 0.5, { x: current });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRandNums(generateRandoms());
    }, 100);
    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 2500);
    const logoAnimateTimeout = setTimeout(() => {
      setLogoAnimate(true);
    }, 6000);
    if (
      !sessionStorage ||
      sessionStorage.getItem("loaded")?.toString().trim() !== "true"
    )
      disableBodyScroll(document);

    const reverseTimeout = setTimeout(() => setReverse(true), 2400);
    return () => {
      clearTimeout(timeout);
      clearTimeout(reverseTimeout);
      clearTimeout(logoAnimateTimeout);
      clearInterval(interval);
    };
  }, []);

  return (
    <TransitionGroup>
      <div className="relative loader-fade-in z-30" onEnter={transitionRandom}>
        <div
          className={`absolute loader-container top-1/2 left-1/2 text-4xl text-center break-all sm:whitespace-nowrap w-4/6 mx-auto ${
            logoAnimate ? "logo-animate" : ""
          }`}
        >
          <p
            className={`text-4xl sm:text-5xl md:text-5-5xl lg:text-6xl font-comfortoo text-transparent bg-clip-text bg-gradient-to-r to-indigo-900 from-blue-400 z-19`}
          >
            ByteTactix
          </p>
        </div>
        <div
          className={`grid-container grid grid-cols-7 grid-rows-7 justify-center items-center justify-items-center content-center custom-grid-columns`}
        >
          {randNums.map((row, i) => (
            <React.Fragment key={i}>
              {row.map((col, j) => (
                <div
                  key={`${i}-${j}`}
                  className={`grid-item bg-white h-12 w-12 sm:h-14 sm:w-14 md:h-18 md:w-18 flex justify-center items-center text-2xl md:text-3xl z-30 ${
                    reverse ? "code-unlocked" : ""
                  }`}
                >
                  {col}
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </TransitionGroup>
  );
};

const Loader = () => {
  const [unmount, setUnmount] = useState(false);
  const [unmountAnimation, setUnmountAnimation] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setUnmountAnimation(true);
    }, 8500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (unmountAnimation) {
      const timeout = setTimeout(() => {
        setUnmount(true);
        enableBodyScroll(document);
        if (sessionStorage) {
          sessionStorage.setItem("loaded", true);
        }
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [unmountAnimation]);

  useEffect(() => {
    if (sessionStorage && sessionStorage.getItem("loaded")) {
      setUnmount(true);
    }
  }, [unmount]);

  return unmount ? (
    <></>
  ) : (
    <div
      className={`main-container h-screen w-screen bg-white fixed top-0 left-0 z-20 ${
        unmountAnimation ? "un-mount" : ""
      }`}
    >
      <div className="general-container relative h-screen w-full">
        <div
          className="absolute top-1/2 left-1/2 text-4xl text-center break-all sm:whitespace-nowrap w-full mx-auto"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <Grid />
        </div>
      </div>
    </div>
  );
};

export default Loader;
