import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const styles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    height: "3.95rem",
    borderColor: "transparent",
    borderRadius: "4px",
    width: "100%",
    fontWeight: 300,
    // backgroundClip: "padding-box",
    // backgroundImage:
    //   "linear-gradient(to right, #60a5fa 0%, #312e81 101%, transparent 100%, transparent 100%)",
    "&:hover": {
      borderColor: "transparent",
    },
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    background: "white",
    height: "100%",
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    background: "white",
  }),
  indicatorSeparator: (baseStyles, state) => ({
    ...baseStyles,
    opacity: "0",
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 300,
    opacity: "0.8",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 300,
  }),
};

const Dropdown = ({
  options = [],
  value,
  placeholder = "Select...",
  name,
  onChange,
}) => {
  return (
    <div
      className="w-full height-fit rounded border border-blue-400 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:ring-1"
      style={{
        minWidth: "250px",
      }}
    >
      <Select
        placeholder={placeholder}
        options={options}
        styles={styles}
        value={options.find((option) => option.value === value)}
        onChange={(e) =>
          onChange?.({
            target: {
              name,
              value: e.value,
            },
          })
        }
      />
    </div>
  );
};

export default Dropdown;
